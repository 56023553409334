import Swiper from 'swiper/bundle'

/* Product Swiper
**************************************************************/
var productSwiper = null;
var productInit = false;

/* Thumbs Swiper
**************************************************************/
var thumbsSwiper = null;
var thumbsInit = false;



/* Which media query
**************************************************************/
function thumbsSwiperMode() {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
    let tablet = window.matchMedia('(min-width: 769px) and (max-width: 1023px)');
    let desktop = window.matchMedia('(min-width: 1025px)');

    // Enable (for mobile)
    if(mobile.matches || tablet.matches) {
      if (!thumbsInit && !productInit && !thumbsInit) {
        thumbsInit = true;
        thumbsSwiper = new Swiper('.thumbs.swiper-container', {
          slidesPerView: 4,
          spaceBetween: 16,
          loop: true,
          freeMode: true,
          watchSlidesProgress: true,
        });

        productInit = true;
        productSwiper = new Swiper('.product-detail.swiper-container', {
          loop: true,
          spaceBetween: 16,
          thumbs: {
            swiper: thumbsSwiper,
          }
        });
      }
    }

    // Disable (for desktop)
    else if(desktop.matches && thumbsSwiper) {
      productSwiper.destroy();
      productInit = false;
      thumbsSwiper.destroy();
      thumbsInit = false;
    }
}

if (document.querySelectorAll('.thumbs.swiper-container').length > 0) {
  /* On Load
  **************************************************************/
  window.addEventListener('load', function() {
    thumbsSwiperMode();
  });

  /* On Resize
  **************************************************************/
  window.addEventListener('resize', function() {
    thumbsSwiperMode();
  });
}
