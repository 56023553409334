document.addEventListener('alpine:init', () => {
  Alpine.store('navigation', {
    basket: false,
    close() {
      this.basket = false
    },
    open(nav) {
      this.close()
      this[nav] = true;
    }
  });
  Alpine.store('overlay', {
    open: false,
    basket: false,
  });
  Alpine.store('summary', {
    open: (((window.innerWidth > 0) ? window.innerWidth : screen.width) > 1024)
  });
})
