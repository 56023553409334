import Alpine from "alpinejs";
import collapse from '@alpinejs/collapse'
import Swiper from 'swiper/bundle'
import 'regenerator-runtime/runtime'
import GLightbox from 'glightbox';
import IMask from 'imask';
import SlimSelect from 'slim-select'
// var card = require("card");


import * as Hammer from 'hammerjs';
import * as Card from "card";

// import Pristine from 'pristinejs';


// window.Pristine = Pristine;
window.Alpine = Alpine;
window.Swiper = Swiper;
window.GLightbox = GLightbox;
window.IMask = IMask;
window.SlimSelect = SlimSelect;
window.Card = Card;

function decrement(e) {
  e.stopPropagation();
  if (e.target && e.target.nodeName === 'BUTTON') {
    const target = e.target.nextElementSibling;
    let value = Number(target.value);
    if (value > 0) {
      value--;
      target.value = value;
    }
  }
}

function increment(e) {
  e.stopPropagation();
  if (e.target && e.target.nodeName === 'BUTTON') {
    const target = e.target.previousElementSibling
    let value = Number(target.value);
    value++;
    target.value = value;
  }
}

if (document.querySelectorAll('#payment')) {
  const c = new Card({
    form: document.querySelector('form#payment'),
    width: 0,
    container: '.card-wrapper',
    formSelectors: {
      numberInput: 'input#cardnumber',
      expiryInput: 'input#expiry',
      cvcInput: 'input#cvv',
      nameInput: 'input#cardname'
    },
    formatting: true, // optional - default true
  });
}


// console.log('Pristine', Pristine)

const defaultConfig = {
  // class of the parent element where the error/success class is added
  classTo: 'form-input-wrapper',
  errorClass: 'c-error',
  successClass: 'is-success',
  // class of the parent element where error text element is appended
  errorTextParent: 'form-input-wrapper',
  // type of element to create for the error text
  errorTextTag: 'p',
  // class of the error text element
  errorTextClass: 'text-validation-error c-mt-2 c-text-caption c-text-strawberry c-ml-2.5'
};

window.onload = function () {
  document.querySelectorAll('button[data-action="increment"]').forEach(btn => btn.addEventListener("click", increment));
  document.querySelectorAll('button[data-action="decrement"]').forEach(btn => btn.addEventListener("click", decrement));

  const forms = document.querySelectorAll('form');
  forms.forEach((form) => {
    const pristine = new Pristine(form, defaultConfig);
    form.addEventListener('submit', function (e) {
      const valid = pristine.validate(); // returns true or false

      if (!valid) e.preventDefault()

      console.log('valid', valid)

      return valid;
    });
  });

  const phoneInputs = document.querySelectorAll('input.msisdn')
  phoneInputs.forEach((phoneInput) => {
    IMask(
      phoneInput, {
        mask: '{\\0} (###) ### ## ##',
        lazy: true,
        definitions: {
          '#': /[0-9]/
        },
        placeholderChar: '_'
      }
    );
  })

  const yearInputs = document.querySelectorAll('input.year')
  yearInputs.forEach((yearInput) => {
    IMask(
      yearInput, {
        mask: '####',
        lazy: true,
        definitions: {
          '#': /[0-9]/
        },
        placeholderChar: ''
      }
    );
  })

  const tokenInputs = document.querySelectorAll('input.token')
  tokenInputs.forEach((tokenInput) => {
    IMask(
      tokenInput, {
        mask: '######',
        lazy: true,
        definitions: {
          '#': /[0-9]/
        },
        placeholderChar: ''
      }
    );
  })

  const zipInputs = document.querySelectorAll('input.zip')
  zipInputs.forEach((zipInput) => {
    IMask(
      zipInput, {
        mask: '#####',
        lazy: false,
        definitions: {
          '#': /[0-9]/
        },
        placeholderChar: '_'
      }
    );
  })

  const tcidInputs = document.querySelectorAll('input.tcid')
  tcidInputs.forEach((tcidInput) => {
    IMask(
      tcidInput, {
        mask: '###########',
        lazy: true,
        definitions: {
          '#': /[0-9]/
        },
      }
    );
  })

  if (document.querySelectorAll('select.slim').length) {
    const selects = document.querySelectorAll('select.slim')

    selects.forEach((select) => {
      new SlimSelect({
        select: select,
        showSearch: false,
        placeholder: select.getAttribute('placeholder'),
        onChange: () => {
          select.classList.add('select-list');
        }
      })
    })
  }
}

if (document.querySelectorAll('.thumbs.swiper-container').length > 0) {
  document.addEventListener('scroll', function(e) {
    requestAnimationFrame(function() {
      let closestToTop = document.querySelector('#product-images .swiper-slide:first-child');
      document.querySelectorAll('#product-images .swiper-slide').forEach(function(slide){
        if (slide.getBoundingClientRect().top - slide.clientHeight - parseInt(window.getComputedStyle(slide).marginTop) < 0) {
          closestToTop = slide;
          return
        }
      });

      const id = closestToTop.getAttribute('id');

      if(document.querySelectorAll('.thumbs.swiper-container .swiper-slide a[href="#'+ id +'"]').length > 0) {
        const items = document.querySelectorAll('.thumbs.swiper-container .swiper-slide a')
        items.forEach((item) => item.parentNode.classList.remove('swiper-slide-thumb-active'))
        document.querySelector('.thumbs.swiper-container .swiper-slide a[href="#'+ id +'"]').parentNode.classList.add('swiper-slide-thumb-active')
      }
    });
  });
}

const lightbox = GLightbox({
  zoomable: true,
  draggable: true
});

const summary = document.querySelector('.summary-trigger > div')
if (summary) {
  const manager = new Hammer.Manager(summary, {
    direction: Hammer.DIRECTION_VERTICAL,
    cssProps: { userSelect: 'auto' }
  });

  // Create a recognizer
  const Swipe = new Hammer.Swipe();

  // Add the recognizer to the manager
  manager.add(Swipe);

  // Subscribe to a desired event
  manager.on('swipe', function(e) {
    if (((window.innerWidth > 0) ? window.innerWidth : screen.width) > 1024) {
      return false
    }

    const direction = e.offsetDirection;

    if (direction === 8) {
      Alpine.store('summary').open = true
    }

    if (direction === 16) {
      Alpine.store('summary').open = false
    }
  });
}


// Components & Modules
require("./modules/components");
require("./modules/lazyload");
require("./modules/store");
require("./modules/thumbs-swiper");

Alpine.plugin(collapse);
Alpine.start();
